import React from "react";
import './SideBar.css';
import { Dropdown, Nav, Sidenav } from "rsuite";
import { CollectionFill, Controller, PeopleFill, PersonWheelchair, TrophyFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const SideBar: React.FC = () => {
    return (
        <Sidenav appearance="inverse" defaultOpenKeys={['4']}>
            <Sidenav.Body>
                <Nav>
                    {/* <Nav.Item as={Link} to="/admin-panel/dashboard" eventKey="1">
                        Dashboard
                    </Nav.Item> */}
                    <Dropdown eventKey="2" title=" Пользователи" icon={<PeopleFill />}>
                        <Dropdown.Item as={Link} to="/admin-panel/all-players" eventKey="2-1">Все Игроки</Dropdown.Item>
                        <Dropdown.Item as={Link} to="/admin-panel/all-partners" eventKey="2-2">Все Партнеры</Dropdown.Item>
                    </Dropdown>
                    <Dropdown eventKey="3" title=" Команды" icon={<PersonWheelchair />}>
                        <Dropdown.Item as={Link} to="/admin-panel/teams" eventKey="3-2">Все команды</Dropdown.Item>
                    </Dropdown>
                    <Dropdown eventKey="4" title=" Турниры" icon={<TrophyFill />}>
                        <Dropdown.Item as={Link} to="/admin-panel/" eventKey="4-1">Управление турнирами</Dropdown.Item>
                    </Dropdown>
                    <Dropdown eventKey="5" title=" Игры" icon={<Controller />}>
                        <Dropdown.Item as={Link} to="/admin-panel/cs2" eventKey="5-1">CS2</Dropdown.Item>
                    </Dropdown>
                    <Dropdown eventKey="6" title=" Лобби" icon={<CollectionFill />}>
                        <Dropdown.Item as={Link} to="/admin-panel/lobbies/all" eventKey="6-1">Все активные лобби</Dropdown.Item>
                    </Dropdown>
                </Nav>
            </Sidenav.Body>
        </Sidenav>
    );
};

export default SideBar;