import React, { useEffect, useState } from 'react';
import { Table, Button, Spinner } from 'react-bootstrap';
import { ArrowRepeat, BoxArrowInRight, Trash } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { TournamentConstants } from '../../../../helpers/constants/tournamentConstants';
import { deleteLobby, getAllActiveLobbies, recycleLobby } from '../../../../http/adminActionsAPI';
import { IMatchLobbyModel } from '../../../../models/dto/lobby/IMatchLobbyModel';
import './AdminLobbyManager.css'; // Добавьте стили, если нужно

const AdminLobbyManager: React.FC = () => {
    const [lobbies, setLobbies] = useState<IMatchLobbyModel[]>([]);
    const [expandedTournaments, setExpandedTournaments] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const navigate = useNavigate();
    const fetchLobbies = async () => {
        setLoading(true);
        const data = await getAllActiveLobbies();
        if (data) {
            setLobbies(data.sort((a, b) => a.tournamentId.localeCompare(b.tournamentId)));
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchLobbies();
    }, []);

    const toggleTournament = (tournamentId: string) => {
        setExpandedTournaments((prev) =>
            prev.includes(tournamentId)
                ? prev.filter((id) => id !== tournamentId)
                : [...prev, tournamentId]
        );
    };

    const handleRestart = async (lobbyId: string) => {
        try {
            const success = await recycleLobby(lobbyId);
            if (success) {
                alert(`Лобби ${lobbyId} успешно перезапущено.`);
            } else {
                alert('Ошибка при перезапуске лобби.');
            }
        } catch (error) {
            console.error('Ошибка перезапуска лобби:', error);
            alert('Ошибка при перезапуске лобби.');
        }
    };

    const handleDelete = async (lobbyId: string) => {
        if (window.confirm('Вы уверены, что хотите удалить лобби?')) {
            try {
                const success = await deleteLobby(lobbyId);
                if (success) {
                    alert(`Лобби ${lobbyId} успешно удалено.`);
                    await fetchLobbies();
                } else {
                    alert('Ошибка при удалении лобби.');
                }
            } catch (error) {
                console.error('Ошибка удаления лобби:', error);
                alert('Ошибка при удалении лобби.');
            }
        }
    };

    const getLobbyIconSrc = (gameTypeId: string) => {
        switch (gameTypeId) {
            case TournamentConstants.CS2.gameTypeId:
                return TournamentConstants.CS2.gameIcon;
            case TournamentConstants.DOTA2.gameTypeId:
                return TournamentConstants.DOTA2.gameIcon;
            default:
        }
    }

    const groupedLobbies = lobbies.reduce<Record<string, IMatchLobbyModel[]>>((acc, lobby) => {
        if (!acc[lobby.tournamentId]) {
            acc[lobby.tournamentId] = [];
        }
        acc[lobby.tournamentId].push(lobby);
        return acc;
    }, {});

    if (loading) {
        return (
            <div className="loading-container">
                <Spinner animation="border" />
            </div>
        );
    }

    return (
        <div className="admin-lobby-manager">
            {Object.entries(groupedLobbies).map(([tournamentId, tournamentLobbies]) => (
                <div key={tournamentId} className="alm-tournament-section">
                    <div
                        className="alm-tournament-header"
                        onClick={() => toggleTournament(tournamentId)}
                        style={{ cursor: 'pointer', fontWeight: 'bold', margin: '10px 0' }}
                    >
                        {tournamentLobbies[0].tournamentName || 'Без названия'}
                    </div>
                    {expandedTournaments.includes(tournamentId) && (
                        <Table bordered hover size="sm" className="alm-table">
                            <thead>
                                <tr>
                                    <th>Игра</th>
                                    <th>Имя</th>
                                    <th>Действия</th>
                                </tr>
                            </thead>
                            <tbody style={{ width: "100%" }}>
                                {tournamentLobbies.map((lobby) => (
                                    <tr key={lobby.lobbyId}>
                                        <td>
                                            <img src={getLobbyIconSrc(lobby.gameTypeId)} className="alm-game-image" />
                                        </td>
                                        <td>{lobby.name}</td>
                                        <td>
                                            <BoxArrowInRight
                                                style={{ cursor: 'pointer', marginRight: 10 }}
                                                title="Войти"
                                                onClick={() => navigate(`/matchlobby/${lobby.gameTypeId}/${lobby.lobbyId}`)}
                                            />
                                            <ArrowRepeat
                                                style={{ cursor: 'pointer', marginRight: 10 }}
                                                title="Перезапустить"
                                                onClick={() => handleRestart(lobby.lobbyId)}
                                            />
                                            <Trash
                                                style={{ cursor: 'pointer' }}
                                                title="Удалить"
                                                onClick={() => handleDelete(lobby.lobbyId)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>
            ))}
        </div>
    );
};

export default AdminLobbyManager;
