import { authInstance } from '.';
import { IMatchLobbyModel } from '../models/dto/lobby/IMatchLobbyModel';
import { ILobbyStateBase } from '../models/dto/lobby/LobbyBase/ILobbyStateBase';
import { IPlayerPageModel } from '../models/dto/player/IPlayerPageModel';
import { ISourceGameServerInfo } from '../models/dto/servers/ISourceGameServerInfo';
import { ICommonTeamModel } from '../models/dto/team/ICommonTeamModel';
import { ITournamentPlacesModel } from '../models/dto/tournament/ITournamentPlacesModel';
import { ServersZonesEnum } from '../models/enums/server/ServersZonesEnum';

export const approveTournament = async (
    tournamentId: string
): Promise<boolean> => {
    return authInstance.get(`AdminActions/ApproveTournament?&tournamentId=${tournamentId}`)
        .then(response => {
            return response.status === 200;
        })
        .catch(e => {
            console.error("Ошибка одобрения турнира:", e.response?.data || e.message);
            return false;
        });
};

export const getAllTeamsByGameTypeId = async (
    gameTypeId: string
): Promise<ICommonTeamModel[] | null> => {
    try {
        const response = await authInstance.get(`AdminActions/GetAllTeamsByGameTypeId?&gameTypeId=${gameTypeId}`);
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения команд');
        return [];
    }
};

export const getTeamByTeamNamePart = async (
    teamNamePart: string
): Promise<ICommonTeamModel[] | null> => {
    try {
        const response = await authInstance.get(`AdminActions/GetTeamByTeamNamePart?&teamNamePart=${teamNamePart}`);
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения команд');
        return [];
    }
};

export const getAllPlayers = async (

): Promise<IPlayerPageModel[] | null> => {
    try {
        const response = await authInstance.get('AdminActions/GetPlayers');
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения игроков');
        return [];
    }
};

export const getAllActiveLobbies = async (): Promise<IMatchLobbyModel[] | false> => {
    try {
        const response = await authInstance.get(`AdminActions/GetAllActiveLobbies`);
        return response.data;
    } catch (e: any) {
        console.log(e.response?.data?.message || 'Ошибка получения списка активных лобби');
        return false;
    }
};

export const deleteLobby = async (lobbyId: string): Promise<boolean> => {
    try {
        await authInstance.post(`AdminActions/DeleteLobby/${lobbyId}`);
        return true;
    } catch (e: any) {
        console.error(e.response?.data?.message || 'Ошибка удаления лобби');
        return false;
    }
};

export const recycleLobby = async (lobbyId: string): Promise<boolean> => {
    try {
        await authInstance.post(`AdminActions/RecycleLobby/${lobbyId}`);
        return true;
    } catch (e: any) {
        console.error(e.response?.data?.message || 'Ошибка перезапуска лобби');
        return false;
    }
};

export const updateCS2Map = async (
    map: FormData,
    mapId: string
): Promise<string> => {
    try {
        const response = await authInstance.put(`CS2Tournaments/UpdateMap/${mapId}`, map);
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка обновления карты');
        return '';
    }
};

export const createCS2Map = async (
    map: FormData
): Promise<string> => {
    try {
        const response = await authInstance.post('CS2Tournaments/AddNewMap', map);
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка добавления карты');
        return '';
    }
};

export const deleteCS2Map = async (
    mapId: string
): Promise<string> => {
    try {
        const response = await authInstance.delete(`CS2Tournaments/RemoveMap/${mapId}`);
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка удаления карты');
        return '';
    }
};

export const checkAndAddTeamToTournament = async (
    teamId: string,
    tournamentId: string
): Promise<string | true> => {
    try {
        const response = await authInstance.get(`ManualTournament/TeamIsValidForTournament`, {
            params: { teamId, tournamentId }
        });

        if (response.status === 200 && !response.data) {
            forceAddTeamToTournament(teamId, tournamentId);
            return true;
        } else {
            return response.data || 'Команда не может участвовать';
        }
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка проверки команды';
    }
};

export const forceAddTeamToTournament = async (
    teamId: string,
    tournamentId: string
): Promise<string | true> => {
    try {
        await authInstance.post(`ManualTournament/ForceAddTeamToTournament`, { teamId, tournamentId });
        return true;
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка добавления команды';
    }
};

export const startMatch = async (matchId: string): Promise<any> => {
    try {
        const response = await authInstance.post('ManualTournament/StartMatch', { matchId });
        return response.data as ILobbyStateBase;
    } catch (e: any) {
        if (e.response?.status === 400) {
            return `Лобби не создано для матча: ${matchId}`;
        } else if (e.response?.status === 500) {
            return e.response?.data?.message || 'Ошибка сервера при старте матча';
        } else {
            return 'Неизвестная ошибка старта матча';
        }
    }
};

export const editMatchDate = async (matchId: string, date: string): Promise<string | true> => {
    try {
        await authInstance.post('ManualTournament/EditMatchDate', { matchId, date });
        return true;
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка изменения даты матча';
    }
};

export const startRegistration = async (tournamentId: string): Promise<string | true> => {
    try {
        await authInstance.post('ManualTournament/StartRegistration', { tournamentId });
        return true;
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка старта регистрации';
    }
};

export const stopRegistration = async (tournamentId: string): Promise<string | true> => {
    try {
        await authInstance.post('ManualTournament/StopRegistration', { tournamentId });
        return true;
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка остановки регистрации';
    }
};
export const regenerateBracket = async (tournamentId: string): Promise<string | true> => {
    try {
        await authInstance.post('ManualTournament/RegenerateTournamentBracket', { tournamentId });
        return true;
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка регенерации сетки';
    }
};

export const addPlayerToTeam = async (teamId: string, playerId: string): Promise<string | true> => {
    try {
        await authInstance.post('ManualTournament/ForceAddPlayerToTeam', { teamId, playerId });
        return true;
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка добавления игрока';
    }
};

export const removePlayerFromTeam = async (teamId: string, playerId: string): Promise<string | true> => {
    try {
        await authInstance.post('ManualTournament/ForceRemovePlayerFromTeam', { teamId, playerId });
        return true;
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка удаления игрока';
    }
};

export const deleteTeamFromTournament = async (
    tournamentId: string,
    teamId: string
): Promise<true | string> => {
    try {
        const response = await authInstance.post('ManualTournament/ForceRemoveTeamFromTournament', {
            tournamentId,
            teamId,
        });

        if (response.status === 200) {
            return true;
        } else {
            return 'Ошибка удаления команды';
        }
    } catch (e: any) {
        if (e.response?.status === 400) {
            return 'Команду не удалось удалить';
        } else {
            return e.response?.data?.message || 'Ошибка удаления команды из турнира';
        }
    }
};

export const addTeamStandin = async (
    tournamentId: string,
    teamId: string,
    playerId: string
): Promise<true | string> => {
    try {
        const response = await authInstance.post('ManualTournament/AddTeamStandin', {
            tournamentId,
            teamId,
            playerId,
        });

        if (response.status === 200) {
            return true;
        } else {
            return 'Ошибка добавления замены';
        }
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка добавления замены для команды';
    }
};

export const removeTeamStandin = async (
    tournamentId: string,
    teamId: string,
    playerId: string
): Promise<true | string> => {
    try {
        const response = await authInstance.post('ManualTournament/RemoveTeamStandin', {
            tournamentId,
            teamId,
            playerId,
        });

        if (response.status === 200) {
            return true;
        } else {
            return 'Ошибка удаления замены';
        }
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка удаления замены для команды';
    }
};

export const endTournament = async (
    tournamentId: string
): Promise<true | ITournamentPlacesModel | string> => {
    try {
        const response = await authInstance.post('ManualTournament/SendEndTournament', {
            tournamentId,
        });

        if (response.status === 200) {
            // Если есть модель с местами, возвращаем её
            if (response.data && response.data.places) {
                return response.data;
            }
            return true; // Если будут переигровки, просто возвращаем true
        } else {
            return 'Ошибка завершения турнира';
        }
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка завершения турнира';
    }
};

export const endDota2Match = async (
    teamId: string,
    matchId: string
): Promise<true | string> => {
    try {
        const response = await authInstance.post('ManualTournament/EndManualDota2Map', {
            matchId, teamId
        });

        if (response.status === 200) {
            return true;
        } else {
            return 'Ошибка окончания Дота2 матча';
        }
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка окончания Дота2 матча';
    }
};

export const restartGameServer = async (
    matchId: string
): Promise<true | string> => {
    try {
        const response = await authInstance.post('ManualTournament/RestartGameServer', {
            matchId,
        });

        if (response.status === 200) {
            return true;
        } else {
            return 'Ошибка перезапуска сервера';
        }
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка перезапуска игрового сервера';
    }
};

export const getTournamentPlaces = async (
    tournamentId: string
): Promise<ITournamentPlacesModel | string> => {
    try {
        const response = await authInstance.get(`/TournamentsCommon/GetTournamentPlaces`, {
            params: { tournamentId }
        });

        if (response.status === 200) {
            return response.data as ITournamentPlacesModel;
        } else {
            return response?.data?.message || 'Ошибка получения мест турнира';
        }
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка получения данных о местах турнира';
    }
};

export const getGameServersInfo = async (zone: ServersZonesEnum): Promise<ISourceGameServerInfo[] | string> => {
    try {
        const response = await authInstance.get(`ManualTournament/GetGameServersInfo`, { params: { zone } });
        return response.data as ISourceGameServerInfo[];
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка получения информации о серверах';
    }
};

export const startSelectedGameServer = async (matchId: string, gameServerId: string): Promise<string | true> => {
    try {
        await authInstance.post(`ManualTournament/StartSelectedGameServer`, { matchId, gameServerId });
        return true;
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка запуска игры на сервере';
    }
};

export const startUpdateChecker = async (): Promise<string> => {
    try {
        await authInstance.get(`ManualTournament/StartUpdateChecker`);
        return 'Update checker started successfully.';
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка запуска проверки обновлений';
    }
};

export const stopUpdateChecker = async (): Promise<string> => {
    try {
        await authInstance.get(`ManualTournament/StopUpdateChecker`);
        return 'Update checker stopped successfully.';
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка остановки проверки обновлений';
    }
};

export const getUpdateCheckerStatus = async (): Promise<string> => {
    try {
        const response = await authInstance.get(`ManualTournament/GetUpdateCheckerStatus`);
        return response.data;
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка получения статуса проверки обновлений';
    }
};

export const updateContainer = async (containerId: string): Promise<string> => {
    try {
        await authInstance.get(`ManualTournament/UpdateContainer`, { params: { containerId } });
        return `Container with ID ${containerId} updated successfully.`;
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка обновления контейнера';
    }
};

export const updateAllVolumes = async (): Promise<string | true> => {
    try {
        await authInstance.get(`ManualTournament/UpdateAllVolumes`);
        return 'All volumes updated successfully.';
    } catch (e: any) {
        return e.response?.data?.message || 'Ошибка обновления всех контейнеров';
    }
};
