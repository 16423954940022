import { useContext, useState } from "react";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";
import { TypeOfMatchEnum } from "../../models/enums/TypeOfMatchEnum";
import { ITournamentInfoModel } from "../../models/dto/tournament/ITournamentInfoModel";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import './MatchesTimetable.css';
import { CheckCircle, InfoCircleFill, PencilFill, X, XCircle } from "react-bootstrap-icons";
import { editMatchDate, endDota2Match, restartGameServer, startMatch } from "../../http/adminActionsAPI";
import { TournamentConstants } from "../../helpers/constants/tournamentConstants";
import { MatchStatusesEnum } from "../../models/enums/tournament/MatchStatusesEnum";

const MatchesTimetable: React.FC<{ tournamentInfo: ITournamentInfoModel, forAdmin?: boolean }> = ({ tournamentInfo, forAdmin }) => {
    const { player } = useContext(AppContext);
    const navigate = useNavigate();
    const [editingMatchId, setEditingMatchId] = useState<string | null>(null);
    const [editedDate, setEditedDate] = useState<string | null>(null);
    const [selectedMatchWinner, setSelectedMatchWinner] = useState('');
    const [selectedMatchId, setSelectedMatchId] = useState('');

    const filteredMatches = tournamentInfo.tournamentMatches.filter(match => {
        if (forAdmin) {
            return match.teamNameA && match.teamNameB;
        } else {
            return true;
        }
    });

    const popoverFastCup = (
        <Popover >
            <Popover.Body>
                Ребут сервера (НЕ ЛОББИ) для уже созданного лобби
            </Popover.Body>
        </Popover>
    );

    const getMatchType = (typeOfMatch: any) => {
        const typeOfMatchEnum: TypeOfMatchEnum = TypeOfMatchEnum[typeOfMatch as keyof typeof TypeOfMatchEnum];
        switch (typeOfMatchEnum) {
            case TypeOfMatchEnum.CommonMatch:
                return tournamentInfo?.upperMatchBracketTypeEnum;
            case TypeOfMatchEnum.UpperMatch:
                return tournamentInfo?.upperMatchBracketTypeEnum;
            case TypeOfMatchEnum.LowerMatch:
                return tournamentInfo?.lowerMatchBracketTypeEnum;
            case TypeOfMatchEnum.ThirdPlaceMatch:
                return tournamentInfo?.thirdPlaceTypeEnum;
            case TypeOfMatchEnum.FinalMatch:
                return tournamentInfo?.finalStageTypeEnum;
            case TypeOfMatchEnum.RobinRound:
                return tournamentInfo?.upperMatchBracketTypeEnum;
            default:
                return '';
        }
    };


    const handleStartMatch = async (matchId: string) => {
        const result = await startMatch(matchId);

        if ('gameTypeId' in result) {
            alert(`Матч успешно начат для matchId: ${matchId}`);
        } else {
            alert(`Ошибка старта матча (статус ${result.status}): ${result.error}`);
        }
    };

    const handleStartServer = async (matchId: string) => {
        const result = await restartGameServer(matchId);
        if (result === true) {
            alert(`Сервер успешно перезапущен для matchId: ${matchId}`);
        } else {
            alert(`Ошибка перезапуска сервера: ${result}`);
            console.error('Ошибка перезапуска сервера:', result);
        }
    };

    const handleEditDate = (matchId: string, currentDate: string | null) => {
        setEditingMatchId(matchId);
        setEditedDate(currentDate);
    };

    const handleSaveDate = async (matchId: string) => {
        if (!editedDate) {
            alert('Дата не выбрана');
            return;
        }

        const utcDate = new Date(editedDate).toISOString();

        try {
            const result = await editMatchDate(matchId, utcDate);
            if (result === true) {
                window.location.reload();
            } else {
                alert(`Ошибка сохранения новой даты: ${result}`);
            }
        } finally {
            setEditingMatchId(null);
            setEditedDate(null);
        }
    };

    const handleCancelEditDate = () => {
        setEditingMatchId(null);
        setEditedDate(null);
    };

    const handleEndDota2Match = async (teamWinnerId: string, matchId: string) => {
        const result = await endDota2Match(teamWinnerId, matchId);
        if (result === true) {
            alert(`Матч успешно завершен (matchId: ${matchId})`);
        } else {
            alert(`Ошибка завершения матча: ${result}`);
            console.error('Ошибка завершения матча:', result);
        }
    };

    const handleEndMatchClick = (matchId: string) => {
        setSelectedMatchId(matchId);
        setSelectedMatchWinner('');
    };

    const handleCancelEndMatch = () => {
        setSelectedMatchId('');
        setSelectedMatchWinner('');
    };

    return (
        <table className="tournament-schedule-table">
            <tbody>
                {filteredMatches.map((match, index) => (
                    <>
                        {!match.isFakeMatch && (
                            <tr key={index}>
                                <td className='table-team-left'>
                                    {match.teamNameA ? (
                                        <div
                                            className={`table-team-link ${player?.playerInTeam(match.teamIdA ?? '') && 'selected'} ${match?.teamNameA?.includes("BYE") ? 'disabled' : ''}`}
                                            onClick={() => !match?.teamNameA?.includes("BYE") && navigate(`/team/${match.teamIdA}`)}
                                        >
                                            {match.teamNameA}
                                        </div>
                                    ) : (
                                        <span>TBD</span>
                                    )}
                                </td>
                                <td className='table-team-vs'>{" vs "}</td>
                                <td className='table-team-right'>
                                    {match.teamNameB ? (
                                        <div
                                            className={`table-team-link ${player?.playerInTeam(match.teamIdB ?? '') && 'selected'} ${match?.teamNameB?.includes("BYE") ? 'disabled' : ''}`}
                                            onClick={() => !match?.teamNameB?.includes("BYE") && navigate(`/team/${match.teamIdB}`)}
                                        >
                                            {match.teamNameB}
                                        </div>
                                    ) : (
                                        <span>TBD</span>
                                    )}
                                </td>
                                <td>
                                    {editingMatchId === match.matchId ? (
                                        <>
                                            <input
                                                type="datetime-local"
                                                value={editedDate || ''}
                                                onChange={(e) => setEditedDate(e.target.value)}
                                            />
                                            <Button variant="success" onClick={() => handleSaveDate(match.matchId)}><CheckCircle /></Button>
                                            <Button variant="secondary" onClick={handleCancelEditDate}><XCircle /></Button>
                                        </>
                                    ) : (
                                        <>
                                            {match.date
                                                ? `${new Date(match.date).toLocaleDateString('ru-RU', { day: '2-digit', month: 'short' })}, ${new Date(match.date).toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}`
                                                : '—'}
                                            {forAdmin && (
                                                <Button variant="dark" onClick={() => handleEditDate(match.matchId, match.date)}>
                                                    <PencilFill />
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </td>
                                <td className='tournament-schedule-match'>
                                    <div onClick={() => navigate(`/match/${match.matchId}`)}>
                                        {getMatchType(match.typeOfMatch)}
                                    </div>
                                </td>
                                {forAdmin && (
                                    <>
                                        <td className='tournament-schedule-match'>
                                            <Button variant="primary" onClick={() => handleStartMatch(match.matchId)}>Start Lobby</Button>
                                        </td>
                                        {tournamentInfo.gameTypeId === TournamentConstants.CS2.gameTypeId && (
                                            <td>
                                                <Button variant="secondary" onClick={() => handleStartServer(match.matchId)}>
                                                    Restart CS2 Server
                                                    {index === 0 && (
                                                        <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={popoverFastCup}>
                                                            <InfoCircleFill />
                                                        </OverlayTrigger>
                                                    )}
                                                </Button>
                                            </td>
                                        )}
                                        {tournamentInfo.gameTypeId === TournamentConstants.DOTA2.gameTypeId && (
                                            <td>
                                                {selectedMatchId === match.matchId ? (
                                                    <>
                                                        <select
                                                            value={selectedMatchWinner}
                                                            onChange={(e) => setSelectedMatchWinner(e.target.value)}
                                                        >
                                                            <option value="">Победитель</option>
                                                            <option value={match.teamIdA || ''}>{match.teamNameA}</option>
                                                            <option value={match.teamIdB || ''}>{match.teamNameB}</option>
                                                        </select>
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => {
                                                                handleEndDota2Match(selectedMatchWinner, match.matchId);
                                                            }}
                                                            disabled={!selectedMatchWinner || match.status === MatchStatusesEnum.Ended}
                                                        >
                                                            End
                                                        </Button>
                                                        <Button variant="secondary" onClick={handleCancelEndMatch}>
                                                            <X />
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <Button variant="danger" onClick={() => handleEndMatchClick(match.matchId)}>MapWinner</Button>
                                                )}
                                            </td>
                                        )}
                                    </>
                                )}
                                <td>
                                    {match.status}
                                </td>
                            </tr>
                        )}
                    </>
                ))}
            </tbody>
        </table>
    );
};

export default MatchesTimetable;